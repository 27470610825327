import type { Chat, ChatContextType } from '@papershift/api/src/chat'
import { notify } from '@papershift/ui/src/Notifier'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import useChatStore from '@/stores/chat/chat.store'
import { differenceInSeconds } from 'date-fns'

const { t } = i18n.global

export default function useChat() {
  const chatStore = useChatStore()

  async function openChat(contextId: string, contextType: ChatContextType) {
    await chatStore.fetchChatByContext(contextId, contextType)

    if (!chatStore.currentChat) {
      notify({
        title: t('chat.open.permission.error.title'),
        message: t('chat.open.permission.error.message'),
        type: 'error',
      })
    }
  }

  function isUnread(chat: Chat) {
    if (!chat.latest_message || !chat.bookmark) return false

    const latestMessageTime = new Date(chat.latest_message.created_at)
    const userBookmarkTime = new Date(chat.bookmark.updated_at)

    return differenceInSeconds(latestMessageTime, userBookmarkTime) > 0
  }

  function extractMessageContent(htmlMessage: string) {
    const el = document.createElement('div')
    el.innerHTML = htmlMessage.replace(/<br>/g, ' ')
    return el.textContent?.trim()
  }

  return {
    openChat,
    isUnread,
    extractMessageContent,
  }
}

addMessages({
  en: {
    chat: {
      open: {
        permission: {
          error: {
            title: 'Cannot open chat',
            message: 'You are not a participant of this chat',
          },
        },
      },
    },
  },

  de: {
    chat: {
      open: {
        permission: {
          error: {
            title: 'Kann den Chat nicht öffnen',
            message: 'Du bist kein Mitglied dieses Chats',
          },
        },
      },
    },
  },
})
