import {
  LockClosedIcon,
  LockOpenIcon,
  PencilSquareOutlineIcon,
} from '@papershift/ui/src/icons'
import type { Chat } from '@papershift/api/src/chat'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import type { Option } from '@papershift/ui/src/Dropdown.vue'
import useChatStore from '@/stores/chat/chat.store'
import { notify } from '@papershift/ui/src/Notifier'

export type Action = 'close' | 'open' | 'edit'
type ActionHandler = (row: Chat) => void
type Listeners = Record<Action, ActionHandler>

const { t } = i18n.global

export default function useTableActions() {
  const { updateChat } = useChatStore()
  const listeners: Listeners = {} as Listeners

  function getActions(chat: Chat) {
    const actions: Option<Action>[] = []

    if (chat.permissions?.actions?.edit) {
      actions.push({
        label: t('actions.edit'),
        value: 'edit',
        icon: PencilSquareOutlineIcon,
      })

      if (chat.open) {
        actions.push({
          label: t('actions.close'),
          value: 'close',
          icon: LockClosedIcon,
          theme: 'danger',
        })
      } else {
        actions.push({
          label: t('actions.open'),
          value: 'open',
          icon: LockOpenIcon,
        })
      }
    }

    return actions
  }

  async function handleAction(row: Chat, actionOption: Option<Action>) {
    listeners[actionOption.value]?.(row)

    if (actionOption.value === 'close') {
      await closeChat(row)
    }

    if (actionOption.value === 'open') {
      await reopenChat(row)
    }
  }

  async function closeChat(row: Chat) {
    try {
      await updateChat(row.id, { open: false })
      notify({
        title: t('success.close.title'),
        message: t('success.close.message'),
      })
    } catch {
      notify({
        title: t('error.close.title'),
        message: t('error.close.message'),
        type: 'error',
      })
    }
  }

  async function reopenChat(row: Chat) {
    try {
      await updateChat(row.id, { open: true })
      notify({
        title: t('success.open.title'),
        message: t('success.open.message'),
      })
    } catch {
      notify({
        title: t('error.open.title'),
        message: t('error.open.message'),
        type: 'error',
      })
    }
  }

  return {
    getActions,
    handleAction,
    onAction(action: Action, handler: ActionHandler) {
      listeners[action] = handler
    },
  }
}

addMessages({
  en: {
    actions: {
      open: 'Reopen Chat',
      close: 'Close Chat',
      edit: 'Edit Chat',
    },
    success: {
      close: {
        title: 'Chat closed',
        message: 'The chat has been successfully closed.',
      },
      open: {
        title: 'Chat reopened',
        message: 'The chat has been successfully reopened.',
      },
      edit: {
        title: 'Edit Chat',
      },
    },
    error: {
      close: {
        title: 'Error',
        message: 'An error occurred while closing the chat.',
      },
      open: {
        title: 'Error',
        message: 'An error occurred while reopening the chat.',
      },
      edit: {
        title: 'Error',
        message: 'Failed to edit chat.',
      },
    },
  },
  de: {
    actions: {
      open: 'Chat wieder öffnen',
      close: 'Chat schließen',
      edit: ' Chat bearbeiten',
    },
    success: {
      close: {
        title: 'Chat geschlossen',
        message: 'Der Chat wurde erfolgreich geschlossen.',
      },
      open: {
        title: 'Chat wieder geöffnet',
        message: 'Der Chat wurde erfolgreich wieder geöffnet.',
      },
      edit: {
        title: 'Chat bearbeiten',
      },
    },
    error: {
      close: {
        title: 'Fehler',
        message: 'Beim Schließen des Chats ist ein Fehler aufgetreten.',
      },
      open: {
        title: 'Fehler',
        message: 'Beim Wiederöffnen des Chats ist ein Fehler aufgetreten.',
      },
      edit: {
        title: 'Fehler',
        message: 'Chat konnte nicht bearbeitet werden.',
      },
    },
  },
})
