<script setup lang="ts">
import { RouterView, useRoute } from 'vue-router'
import ChatView from '@/views/ChatView.vue'

const route = useRoute()
</script>

<template>
  <RouterView />
  <ChatView v-if="route.name" />
</template>
