<script setup lang="ts">
import { computed, nextTick, ref, watch } from 'vue'
import type { Chat, ChatMessage } from '@papershift/api/src/chat'
import {
  XMarkIcon,
  PaperAirplaneIcon,
  ArrowLongDownIcon,
  EllipsisVerticalIcon,
} from '@papershift/ui/src/icons'
import useChatStore from '@/stores/chat/chat.store'
import { useI18n } from '@papershift/locale/src/i18n'
import useModalToggler from '@papershift/ui/src/utils/use-modal-toggler'
import ChatEdit from './chat-view/ChatEdit.vue'
import ChatMemberEdit from './chat-view/ChatMemberEdit.vue'
import ChatMemberAdd from './chat-view/ChatMemberAdd.vue'
import ChatMemberList from './chat-view/ChatMemberList.vue'
import {
  useElementVisibility,
  useInfiniteScroll,
  useScroll,
} from '@vueuse/core'
import useAuthStore from '@/stores/auth/auth.store'
import { createChannel } from '@/utils/cable'
import useChatBookmark from './composables/use-chat-bookmark'
import useTableActions, {
  type Action,
} from './chat-view/composables/use-table-actions'
import { confirm } from '@papershift/ui/src/Confirm'
import type { Option } from '@papershift/ui/src/Dropdown.vue'
import useChatEdit from './composables/use-chat-edit'
import RichEditor from '@papershift/ui/src/RichEditor.vue'
import useChat from '@/views/composables/use-chat'

type BroadcastedData = {
  action: string
  message: ChatMessage
}

const MIN_TOP_DISTANCE = 128 // two messages - one message is 64 px high at the moment

const authStore = useAuthStore()
const { t } = useI18n()
const { isModalActive, toggleModal } = useModalToggler()
const { extractMessageContent } = useChat()

const messageHtmlContent = ref('')
const editingUserId = ref('')
const firstPagesLoaded = ref(false)

const messagesContainerRef = ref<HTMLDivElement | null>(null)

const chat = computed<Chat>(() => chatStore.currentChat!)

const chatStore = useChatStore()
const chatMembers = computed(() => chatStore.chatMembers)

const { deferBookmarkUpdate, triggerBookmarkUpdate, abortBookmarkUpdate } =
  useChatBookmark()

const { isEditingChat, setEditingChat, refetchChat } = useChatEdit(chat)

const { getActions, handleAction, onAction } = useTableActions()

onAction('edit', () => {
  setEditingChat(true)
})

let channel: any = null

function subscribeChannel() {
  channel = createChannel(
    {
      channel: 'ChatChannel',
      chat_id: chat.value.id,
      user_id: authStore.user!.id,
    },
    { received: handleMessage }
  )
}

function closeChat() {
  channel.unsubscribe()
  abortBookmarkUpdate()
  chatStore.closeCurrentChat()
  allMessagesRead.value = true
}

async function handleMessage(data: BroadcastedData) {
  const newMessage = data.message
  chatStore.prependMessage(chat.value.id, newMessage)

  await nextTick()
  messagesContainerRef.value!.lastElementChild!.scrollIntoView(false)

  deferBookmarkUpdate()

  // if the message is from current user, immediately mark chat as read
  // TODO: websocket interception does not work in tests, see onboarding-overview-actions.spec.ts
  /* v8 ignore start */
  if (newMessage.author?.id === authStore.user!.id) {
    triggerBookmarkUpdate()
    allMessagesRead.value = true
  }
  /* v8 ignore stop */
}

async function submit() {
  const message = extractMessageContent(messageHtmlContent.value)

  if (!message) return

  await chatStore.createChatMessage(
    chat.value.id,
    messageHtmlContent.value.trim()
  )
  messageHtmlContent.value = ''
}

async function fetchChatMembers() {
  toggleModal(true)
  chatStore.fetchChatMembersWithRoles(chat.value.id)
}

function isFollowUp(message: ChatMessage, index: number) {
  return (
    chatStore.currentChatMessages[index - 1]?.author_id === message.author_id
  )
}

async function loadInitialMessages() {
  firstPagesLoaded.value = false

  await chatStore.fetchMessagesForCurrentChat(true)
  await nextTick()

  while (
    chatStore.currentChatCanLoadMoreMessages() &&
    // not enough items yet for useInfiniteScroll to take over
    messagesContainerRef.value!.scrollHeight <=
      messagesContainerRef.value!.clientHeight + MIN_TOP_DISTANCE
  ) {
    await chatStore.fetchMessagesForCurrentChat(false)
    await nextTick()
  }

  // do the initial scroll to bottom
  messagesContainerRef.value!.lastElementChild?.scrollIntoView(false)

  firstPagesLoaded.value = true
  deferBookmarkUpdate()
}

async function loadMoreMessages() {
  const preLoadHeight = messagesContainerRef.value!.scrollHeight

  await chatStore.fetchMessagesForCurrentChat(false)
  await nextTick()

  // restore the "scrolled distance from the bottom",
  // but only if the container has been scrolled all the way to the top -
  // if it hasn't been scrolled to the top fully, this happens automagically
  //
  // useScroll is used to do this check,
  // because useScroll is what useInfiniteScroll uses under the hood
  if (useScroll(messagesContainerRef).arrivedState.top) {
    const postLoadHeight = messagesContainerRef.value!.scrollHeight
    messagesContainerRef.value!.scrollBy({
      top: postLoadHeight - preLoadHeight,
      behavior: 'instant',
    })
  }
}

watch(
  () => chatStore.currentChat?.id,
  async (currentChatId) => {
    if (currentChatId) {
      channel?.unsubscribe()
      subscribeChannel()
      await loadInitialMessages()
    }
  }
)

useInfiniteScroll(messagesContainerRef, loadMoreMessages, {
  direction: 'top',
  distance: MIN_TOP_DISTANCE,
  canLoadMore: () =>
    firstPagesLoaded.value && chatStore.currentChatCanLoadMoreMessages(),
})

const unreadLineRef = ref(null)
const allMessagesRead = ref(true)
const unreadLineRefIsVisible = useElementVisibility(unreadLineRef)

const handleUnreadVisibility = (
  message: ChatMessage & { isUnread: boolean }
) => {
  const isUnreadStatus = message.isUnread
  if (isUnreadStatus) {
    allMessagesRead.value = false
  }
  return isUnreadStatus
}

async function handleSelectOption(option: Option<Action>) {
  if (option.value === 'edit') {
    handleAction(chat.value, option)
  } else {
    const confirmed = await confirm(
      t(`confirm.${option.value}.message`),
      t(`confirm.${option.value}.button_label`),
      t(`confirm.${option.value}.title`)
    )

    if (confirmed) {
      await handleAction(chat.value, option)
      closeChat()
      chatStore.fetchChats()
    }
  }
}
</script>

<template>
  <teleport to="body">
    <div
      class="pointer-events-none z-10 fixed inset-y-0 right-auto sm:right-0 flex max-w-full sm:pl-10"
    >
      <Transition
        appear
        enter-active-class="transform transition ease-in-out duration-800 sm:duration-500"
        enter-from-class="translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transform transition ease-in-out duration-800 sm:duration-500"
        leave-from-class="translate-x-0"
        leave-to-class="translate-x-full"
      >
        <div
          v-if="chatStore.currentChat !== null"
          id="chat-view"
          class="chat pointer-events-auto w-screen max-w-full sm:max-w-md"
        >
          <div class="flex h-full flex-col bg-white py-6 shadow-xl">
            <div class="px-4 sm:px-6">
              <div class="flex items-start justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900">
                  {{ chat.subject }}
                </h3>

                <div class="ml-3 flex h-7 items-start">
                  <Dropdown
                    v-if="getActions(chat).length > 0"
                    :id="`chat-view-actions-${chat.id}`"
                    :options="getActions(chat)"
                    class="actions-dropdown mr-3"
                    @option-select="handleSelectOption($event)"
                  >
                    <template #trigger>
                      <EllipsisVerticalIcon
                        class="h-6 w-6"
                        aria-hidden="true"
                      />
                    </template>
                  </Dropdown>
                  <button
                    type="button"
                    class="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    @click="closeChat()"
                  >
                    <span class="absolute -inset-2.5" />
                    <span class="sr-only">Close panel</span>
                    <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>

            <div class="mt-3 sm:mt-0 px-4 sm:px-6">
              <button
                class="text-sm text-pink-600 hover:text-pink-900 self-start"
                @click="fetchChatMembers()"
              >
                {{ t('participants.button') }}
              </button>
            </div>

            <Modal
              :is-active="isModalActive"
              :title="t('participants.modal.title')"
              @close="toggleModal(false)"
            >
              <ChatMemberEdit
                v-if="chat.permissions?.chat_membership?.manage"
                :chat-id="chat.id"
                :user-id="editingUserId"
                :can-delete="chat.permissions?.chat_membership?.manage ?? false"
                @close="editingUserId = ''"
                @updated="fetchChatMembers()"
                @removed="fetchChatMembers()"
              />
              <div class="mt-5">
                <ChatMemberAdd
                  v-if="chat.permissions?.chat_membership?.manage"
                  :chat-id="chat.id"
                  @added="fetchChatMembers()"
                />
              </div>
              <div>
                <ChatMemberList
                  :members="chatMembers"
                  :can-edit="chat.permissions?.chat_membership?.manage ?? false"
                  @cancel-click="toggleModal(false)"
                  @edit-click="editingUserId = $event"
                />
              </div>
            </Modal>

            <div
              ref="messagesContainerRef"
              class="chat-messages relative mt-6 flex-1 px-4 sm:px-6 overflow-y-scroll grow flex flex-col"
            >
              <div
                v-for="(message, i) in chatStore.currentChatMessages"
                :key="message.id"
              >
                <div
                  v-if="handleUnreadVisibility(message)"
                  ref="unreadLineRef"
                  class="w-full"
                >
                  <div
                    class="text-pink-400 text-sm flex flex-wrap justify-between"
                  >
                    <div>New</div>
                    <ArrowLongDownIcon class="w-4 h-4" />
                  </div>

                  <div class="border-t border-pink-300 mb-4"></div>
                </div>

                <ChatMessage
                  :key="message.id"
                  class="mb-4"
                  :message="message"
                  :is-follow-up="isFollowUp(message, i)"
                />
              </div>

              <div
                v-if="!(allMessagesRead || unreadLineRefIsVisible)"
                class="w-full text-center text-sm text-pink-400 py-3"
              >
                {{ t('info.scroll_more') }}
              </div>
            </div>

            <div
              v-if="chat.open"
              class="sticky bottom-0 px-6 flex border-t bg-white"
            >
              <RichEditor
                :content="messageHtmlContent"
                class="mt-2 w-11/12 h-auto p-3 pb-0 pr-6 scrollbar-hidden text-sm text-black/70"
                :editable="true"
                :placeholder="t('type_message_prompt')"
                :compact="true"
                @enter-pressed="submit"
                @update="messageHtmlContent = $event"
              />
              <button
                type="button"
                class="ml-2 mt-6 h-8 w-8 bg-indigo-300 hover:bg-indigo-400 text-white rounded-full focus:outline-none flex items-center justify-center"
                :aria-label="t('send_message')"
                @click="submit()"
              >
                <PaperAirplaneIcon class="w-4 h-4" />
              </button>
            </div>
            <div v-else class="sticky bottom-0 px-6 flex border-t bg-white">
              <div
                class="text-center w-full p-2 mt-5 text-sm text-red-800 rounded-lg bg-red-50"
              >
                {{ t('info.closed_chat') }}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </teleport>

  <ChatEdit
    :chat-data="isEditingChat ? chat : null"
    @close="setEditingChat(false)"
    @updated="refetchChat()"
  />
</template>

/* v8 ignore start */
<i18n locale="en">
info:
  scroll_more: Scroll up to see unread messages
  closed_chat: This chat is closed. Reopen it to send messages.
participants:
  button:
    Participants
  modal:
    title:
      Manage Participants
confirm:
  close:
    title: Close Chat
    message: Are you sure you want to close this chat?
    button_label: Close
  open:
    title: Reopen Chat
    message: Are you sure you want to reopen this chat?
    button_label: Open
type_message_prompt: Type a message
send_message: Send message
</i18n>

<i18n locale="de">
info:
  scroll_more: Nach oben scrollen, um ungelesene Nachrichten zu sehen
  closed_chat: Dieser Chat ist geschlossen. Öffne ihn, um Nachrichten zu senden.
participants:
  button:
    Teilnehmer
  modal:
    title:
      Teilnehmer
confirm:
  close:
    title: Chat schließen
    message: Möchtest du diesen Chat wirklich schließen?
    button_label: Schließen
  open:
    title: Chat wieder öffnen
    message: Möchtest du diesen Chat wirklich wieder öffnen?
    button_label: Öffnen
type_message_prompt: Schreibe eine Nachricht
send_message: Sende Nachricht
</i18n>
/* v8 ignore stop */

<style scoped>
.actions-dropdown :deep(button) {
  @apply ring-0 px-1 py-0;
}
</style>
