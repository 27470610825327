<script setup lang="ts">
import { default as ToolbarButton } from './EditorToolbarButton.vue'
import {
  EditorIconAlignCenter,
  EditorIconAlignLeft,
  EditorIconAlignRight,
  EditorIconBold,
  EditorIconCode,
  EditorIconCodeBlock,
  EditorIconH1,
  EditorIconH2,
  EditorIconH3,
  EditorIconItalic,
  EditorIconListOrdered,
  EditorIconListUnordered,
  EditorIconQuoteText,
  EditorIconStrikethrough,
} from '../icons'
import type { Editor } from '@tiptap/vue-3'
import { Tippy } from 'vue-tippy'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import LinkButton from './LinkButton.vue'

const { t } = i18n.global

defineProps<{
  editor?: Editor
}>()

addMessages({
  en: {
    button: {
      formatting: {
        bold: 'Bold',
        italic: 'Italic',
        strikethrough: 'Strikethrough',
        code: 'Code',
      },
      align: {
        left: 'Align Left',
        center: 'Align Center',
        right: 'Align Right',
      },
      heading: {
        h1: 'Heading 1',
        h2: 'Heading 2',
        h3: 'Heading 3',
      },
      other: {
        bulletlist: 'Bullet List',
        orderedlist: 'Ordered List',
        codeblock: 'Code Block',
        quote: 'Quote',
        link: 'Link',
      },
    },
  },
  de: {
    button: {
      formatting: {
        bold: 'Fett',
        italic: 'Kursiv',
        strikethrough: 'Durchgestrichen',
        code: 'Code',
      },
      align: {
        left: 'Links ausrichten',
        center: 'Zentriert',
        right: 'Rechts ausrichten',
      },
      heading: {
        h1: 'Überschrift 1',
        h2: 'Überschrift 2',
        h3: 'Überschrift 3',
      },
      other: {
        bulletlist: 'Aufzählung',
        orderedlist: 'Nummerierte Liste',
        codeblock: 'Code-Block',
        quote: 'Zitat',
        link: 'Link',
      },
    },
  },
})
</script>

<template>
  <div class="control-group">
    <div v-if="editor" class="flex divide-x p-2">
      <div class="flex gap-4 pr-4">
        <Tippy :content="t('button.formatting.bold')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleBold().run()"
            :active="editor.isActive('bold')"
            @click="editor.chain().focus().toggleBold().run()"
          >
            <EditorIconBold />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.formatting.italic')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleItalic().run()"
            :active="editor.isActive('italic')"
            @click="editor.chain().focus().toggleItalic().run()"
          >
            <EditorIconItalic />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.formatting.strikethrough')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleStrike().run()"
            :active="editor.isActive('strike')"
            @click="editor.chain().focus().toggleStrike().run()"
          >
            <EditorIconStrikethrough />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.formatting.code')" placement="top">
          <ToolbarButton
            :disabled="!editor.can().chain().focus().toggleCode().run()"
            :active="editor.isActive('code')"
            @click="editor.chain().focus().toggleCode().run()"
          >
            <EditorIconCode />
          </ToolbarButton>
        </Tippy>
      </div>

      <div class="flex gap-4 px-4">
        <Tippy :content="t('button.align.left')" placement="top">
          <ToolbarButton
            :active="editor.isActive({ textAlign: 'left' })"
            @click="editor.chain().focus().setTextAlign('left').run()"
          >
            <EditorIconAlignLeft />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.align.center')" placement="top">
          <ToolbarButton
            :active="editor.isActive({ textAlign: 'center' })"
            @click="editor.chain().focus().setTextAlign('center').run()"
          >
            <EditorIconAlignCenter />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.align.right')" placement="top">
          <ToolbarButton
            :active="editor.isActive({ textAlign: 'right' })"
            @click="editor.chain().focus().setTextAlign('right').run()"
          >
            <EditorIconAlignRight />
          </ToolbarButton>
        </Tippy>
      </div>

      <div class="flex gap-4 px-4">
        <Tippy :content="t('button.heading.h1')" placement="top">
          <ToolbarButton
            :active="editor.isActive('heading', { level: 1 })"
            @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          >
            <EditorIconH1 />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.heading.h2')" placement="top">
          <ToolbarButton
            :active="editor.isActive('heading', { level: 2 })"
            @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          >
            <EditorIconH2 />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.heading.h3')" placement="top">
          <ToolbarButton
            :active="editor.isActive('heading', { level: 3 })"
            @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          >
            <EditorIconH3 />
          </ToolbarButton>
        </Tippy>
      </div>

      <div class="flex gap-4 pl-4">
        <Tippy :content="t('button.other.bulletlist')" placement="top">
          <ToolbarButton
            :active="editor.isActive('bulletList')"
            @click="editor.chain().focus().toggleBulletList().run()"
          >
            <EditorIconListUnordered />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.other.orderedlist')" placement="top">
          <ToolbarButton
            :active="editor.isActive('orderedList')"
            @click="editor.chain().focus().toggleOrderedList().run()"
          >
            <EditorIconListOrdered />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.other.codeblock')" placement="top">
          <ToolbarButton
            :active="editor.isActive('codeBlock')"
            @click="editor.chain().focus().toggleCodeBlock().run()"
          >
            <EditorIconCodeBlock />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.other.quote')" placement="top">
          <ToolbarButton
            :active="editor.isActive('blockquote')"
            @click="editor.chain().focus().toggleBlockquote().run()"
          >
            <EditorIconQuoteText />
          </ToolbarButton>
        </Tippy>

        <Tippy :content="t('button.other.link')" placement="top">
          <LinkButton :editor="editor" />
        </Tippy>
      </div>
    </div>
  </div>
</template>
