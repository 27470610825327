<script lang="ts">
import { computed } from 'vue'

type ButtonType = 'button' | 'submit' | 'reset'
type ButtonAppearance = 'primary' | 'secondary'

export type Props = {
  id: string
  type?: ButtonType
  appearance?: ButtonAppearance
  tabindex?: number
  label: string
}
</script>

<script setup lang="ts">
const props = withDefaults(defineProps<Props>(), {
  type: 'button',
  appearance: 'primary',
})

const classes = computed(() => {
  switch (props.appearance) {
    case 'secondary':
      return 'bg-white text-gray-900 ring-1 ring-gray-300 hover:bg-gray-100'
    case 'primary':
    default:
      return 'bg-pink-600 text-white hover:bg-pink-500'
  }
})
</script>

<template>
  <button
    :id="id"
    :type="type"
    :tabindex="tabindex"
    class="rounded-md px-4 py-2 text-center text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-slate-600 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500"
    :class="classes"
  >
    {{ label }}
  </button>
</template>
