<script setup lang="ts">
import { useEditor, EditorContent, Extension } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Placeholder from '@tiptap/extension-placeholder'
import TaskList from '@tiptap/extension-task-list'
import TaskItem from '@tiptap/extension-task-item'
import Link from '@tiptap/extension-link'
import { onMounted, watch } from 'vue'
import EditorToolbar from './editor/EditorToolbar.vue'
import EditorToolbarMobile from './editor/EditorToolbarMobile.vue'

const props = defineProps<{
  content?: string
  editable?: boolean
  placeholder: string
  compact?: boolean
}>()

const emit = defineEmits<{
  update: [string]
  'enter-pressed': []
}>()

const editor = useEditor({
  async onUpdate({ editor }) {
    emit('update', editor.getHTML())
  },
  extensions: [
    StarterKit.configure({
      history: false,
    }),
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
    TextAlign.configure({
      types: ['heading', 'paragraph'],
    }),
    TaskList,
    TaskItem.configure({
      nested: true,
    }),
    Link.configure(),
    Extension.create({
      addKeyboardShortcuts: () => {
        return {
          Enter: () => props.compact,
        }
      },
    }),
  ],
  editorProps: {
    handleKeyDown: (_, event: KeyboardEvent) => {
      const enterPressed = event.key === 'Enter'
      const otherKeysNotPressed =
        !event.metaKey && !event.ctrlKey && !event.shiftKey && !event.altKey

      if (enterPressed && otherKeysNotPressed) {
        emit('enter-pressed')
      }
    },
    attributes: {
      class: `prose prose-sm max-w-full m-5 focus:outline-none rounded-md ${
        props.compact ? 'min-h-2 max-h-96 overflow-scroll' : 'min-h-72'
      }`,
    },
  },
})

onMounted(() => {
  watch(
    () => props.content,
    (content) => {
      if (editor.value.getHTML() !== content) {
        editor.value.commands.setContent(content, false)
        editor.value.options.editable = props.editable
      }
    },
    { immediate: true }
  )
})
</script>

<template>
  <div v-if="editor">
    <EditorToolbar
      v-if="!compact && editable"
      class="hidden sm:flex sticky top-20 z-10 border border-slate-300 bg-white rounded-t-md"
      :editor="editor"
    />
    <EditorToolbarMobile
      v-if="!compact && editable"
      class="sticky top-20 z-10 border border-slate-300 bg-white sm:hidden rounded-t-md"
      :editor="editor"
    />
    <EditorContent
      class="border border-slate-300 bg-white"
      :class="compact ? 'rounded-md' : 'rounded-b-md'"
      :editor="editor"
    />
  </div>
</template>

<style scoped>
:deep(.tiptap) .is-empty::before {
  @apply h-0 float-left content-[attr(data-placeholder)] italic text-slate-300 pointer-events-none;
}

:deep(.tiptap) ul[data-type='taskList'] li {
  @apply flex gap-2;
}

:deep(.tiptap) ul[data-type='taskList'] li label {
  @apply p-0;
}

:deep(.tiptap) ul[data-type='taskList'] li p {
  @apply m-0;
}
</style>
