import api from './api'
import type { EditDeletePermission } from './types'
import type { Role } from './role'
import type { User } from './user'
import type {
  JsonApiListResponse,
  JsonApiResponse,
} from '@papershift/jsonapi/src/types'
import { transformQueryFilters, type FilterItem } from './filter-utils'

export type ChatContextType = 'offboarding' | 'onboarding' | 'team' | 'contract'

export type Chat = {
  id: string
  context_id: string
  context_type: ChatContextType
  subject: string
  latest_message?: LatestMessage
  bookmark?: ChatBookmark
  open: boolean
  permissions?: {
    actions?: EditDeletePermission
    chat_membership?: {
      manage: boolean
    }
  }
}

export type ChatMessage = {
  id: string
  author_id: string
  chat_id: string
  content: string
  created_at: string
  author?: User
}

export type LatestMessage = {
  id: string
  content: string
  created_at: string
  author: string
}

export type ChatMember = {
  id: string
  email: string
  name: string
  roles?: Role[]
}

export type ChatBookmark = {
  id: string
  chat_id: string
  user_id: string
  updated_at: string
}

export function listChats(
  page: number = 1,
  filters: FilterItem[] = []
): Promise<JsonApiListResponse<Chat>> {
  return api
    .url('/api/v1/chats')
    .query({
      'page[number]': page,
      'meta[permissions]': 'actions',
      include: 'latest_message,bookmark',
      sort: '-open,-updated_at',
      ...transformQueryFilters(filters),
    })
    .get()
    .json()
}

export function listChatsByContext(
  contextId: string,
  contextType: ChatContextType
): Promise<JsonApiListResponse<Chat>> {
  return api
    .url('/api/v1/chats')
    .query({
      include: 'bookmark',
      'meta[permissions]': 'actions,chat_membership',
      'filter[context_type]': `eq:${contextType}`,
      'filter[context_id]': `eq:${contextId}`,
    })
    .get()
    .json()
}

export function listMessagesByChat(
  chatId: string
): Promise<JsonApiListResponse<ChatMessage>> {
  return api
    .url(`/api/v1/chats/${chatId}/messages`)
    .query({
      include: 'author',
    })
    .get()
    .json()
}

export function createChatMessage(
  chatId: string,
  content: string
): Promise<JsonApiResponse<ChatMessage>> {
  return api
    .url(`/api/v1/chats/${chatId}/messages`)
    .post({
      data: {
        type: 'message',
        attributes: {
          content,
        },
      },
    })
    .json()
}

export function listChatMembers(
  chatId: string
): Promise<JsonApiListResponse<ChatMember>> {
  return api.url(`/api/v1/chats/${chatId}/members?include=roles`).get().json()
}

export function updateChatBookmark(
  chatId: string
): Promise<JsonApiResponse<ChatBookmark>> {
  return api.url(`/api/v1/chats/${chatId}/bookmark`).put().json()
}

export async function updateChat(
  chatId: string,
  payload: Partial<Chat>
): Promise<JsonApiResponse<Chat>> {
  return api
    .url(`/api/v1/chats/${chatId}`)
    .put({
      data: {
        type: 'chat',
        attributes: {
          open: payload.open,
          subject: payload.subject,
        },
      },
    })
    .json()
}
