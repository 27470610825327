<script setup lang="ts">
import ToolbarButton from './EditorToolbarButton.vue'
import Modal from '../Modal.vue'
import Input from '../Input.vue'
import Button from '../Button.vue'
import { LinkIcon } from '../icons'
import useLinkManager from './composables/use-link-manager'
import type { Editor } from '@tiptap/vue-3'
import i18n, { addMessages } from '@papershift/locale/src/i18n'

const { t } = i18n.global

addMessages({
  en: {
    modal: {
      cancel: 'Cancel',
      delete: 'Remove link',
      submit: 'Save link',
      title: 'Enter URL',
    },
  },
  de: {
    modal: {
      cancel: 'Abbrechen',
      delete: 'Link entfernen',
      submit: 'Link speichern',
      title: 'URL eingeben',
    },
  },
})

const props = defineProps<{
  editor: Editor
}>()

const {
  isModalActive,
  linkInput,
  toggleModal,
  showLinkModal,
  submitLink,
  unsetLink,
} = useLinkManager(props.editor)
</script>

<template>
  <ToolbarButton :active="editor.isActive('link')" @click="showLinkModal()">
    <LinkIcon aria-hidden="true" />
  </ToolbarButton>

  <Modal
    :is-active="isModalActive"
    :title="t('modal.title')"
    @close="toggleModal(false)"
  >
    <form @submit.prevent="submitLink">
      <Input
        v-if="isModalActive"
        id="link-input"
        class="mt-4"
        label=""
        placeholder="https://"
        :model-value="linkInput"
        @update:model-value="linkInput = $event as string"
      />

      <div
        class="flex flex-col-reverse justify-between mt-5 sm:mt-4 sm:flex-row"
      >
        <div class="mt-3 sm:mt-0 text-center">
          <button
            id="link_delete_btn"
            type="button"
            :tabindex="99"
            :label="t('modal.delete')"
            class="h-full text-sm text-rose-600 hover:text-rose-700 disabled:cursor-not-allowed disabled:text-gray-400"
            @click="unsetLink()"
          >
            {{ t('modal.delete') }}
          </button>
        </div>

        <div class="sm:ml-auto sm:flex sm:flex-row-reverse">
          <Button
            id="link_submit_btn"
            appearance="primary"
            :tabindex="97"
            type="submit"
            :label="t('modal.submit')"
            class="w-full sm:ml-3 sm:w-auto"
          />

          <Button
            id="link_cancel_btn"
            appearance="secondary"
            :tabindex="98"
            type="button"
            :label="t('modal.cancel')"
            class="mt-3 w-full sm:mt-0 sm:w-auto"
            @click="toggleModal(false)"
          />
        </div>
      </div>
    </form>
  </Modal>
</template>
