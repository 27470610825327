import useDisplaySettingsStore from '@/stores/display-settings/display-settings.store'
import format from 'date-fns/format'
import { intlFormatDistance } from 'date-fns'

type TimeFormat = 'HH:mm:ss' | 'HH:mm'

export function useLocalizedDate() {
  const displaySettingsStore = useDisplaySettingsStore()

  function formatDate(date?: string, timeFormat?: TimeFormat) {
    if (!date) return null

    const dateFormat = displaySettingsStore.settings.date_format

    if (!dateFormat) return null

    const formatting = timeFormat ? `${dateFormat}, ${timeFormat}` : dateFormat

    return format(new Date(date), formatting)
  }

  function formatDistance(date: string) {
    return intlFormatDistance(new Date(date), new Date(), {
      locale: displaySettingsStore.settings.language,
    })
  }

  return {
    formatDate,
    formatDistance,
  }
}
