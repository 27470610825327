<script setup lang="ts">
import {
  EditorIconAlignCenter,
  EditorIconAlignLeft,
  EditorIconAlignRight,
  EditorIconBold,
  EditorIconCode,
  EditorIconCodeBlock,
  EditorIconH1,
  EditorIconH2,
  EditorIconH3,
  EditorIconItalic,
  EditorIconListOrdered,
  EditorIconListUnordered,
  EditorIconQuoteText,
  EditorIconStrikethrough,
  ChevronDownIcon,
} from '../icons'
import type { Editor } from '@tiptap/vue-3'
import type { Option } from '../Dropdown.vue'
import Dropdown from '../Dropdown.vue'
import DropdownOption from '../DropdownOption.vue'
import { ref } from 'vue'
import i18n, { addMessages } from '@papershift/locale/src/i18n'
import LinkButton from './LinkButton.vue'

const { t } = i18n.global

addMessages({
  en: {
    text_style: {
      bold: 'Bold',
      italic: 'Italic',
      strikethrough: 'Strikethrough',
      code: 'Code',
    },
    align: {
      left: 'Align Left',
      center: 'Align Center',
      right: 'Align Right',
    },
    heading: {
      h1: 'Heading 1',
      h2: 'Heading 2',
      h3: 'Heading 3',
    },
    list: {
      bulletlist: 'Bullet List',
      orderedlist: 'Ordered List',
    },
    block: {
      codeblock: 'Code Block',
      quote: 'Quote',
    },
  },
  de: {
    text_style: {
      bold: 'Fett',
      italic: 'Kursiv',
      strikethrough: 'Durchgestrichen',
      code: 'Code',
    },
    align: {
      left: 'Links ausrichten',
      center: 'Zentriert',
      right: 'Rechts ausrichten',
    },
    heading: {
      h1: 'Überschrift 1',
      h2: 'Überschrift 2',
      h3: 'Überschrift 3',
    },
    list: {
      bulletlist: 'Aufzählung',
      orderedlist: 'Nummerierte Liste',
    },
    block: {
      codeblock: 'Code-Block',
      quote: 'Zitat',
    },
  },
})

const props = defineProps<{
  editor?: Editor
}>()

const textStyleOptions = [
  { value: 'bold', label: t('text_style.bold'), icon: EditorIconBold },
  { value: 'italic', label: t('text_style.italic'), icon: EditorIconItalic },
  {
    value: 'strike',
    label: t('text_style.strikethrough'),
    icon: EditorIconStrikethrough,
  },
  { value: 'code', label: t('text_style.code'), icon: EditorIconCode },
]

const headingOptions = [
  { value: 1, label: t('heading.h1'), icon: EditorIconH1 },
  { value: 2, label: t('heading.h2'), icon: EditorIconH2 },
  { value: 3, label: t('heading.h3'), icon: EditorIconH3 },
]
const textAlignOptions = [
  { value: 'left', label: t('align.left'), icon: EditorIconAlignLeft },
  { value: 'center', label: t('align.center'), icon: EditorIconAlignCenter },
  { value: 'right', label: t('align.right'), icon: EditorIconAlignRight },
]
const listOptions = [
  {
    value: 'bulletList',
    label: t('list.bulletlist'),
    icon: EditorIconListUnordered,
  },
  {
    value: 'orderedList',
    label: t('list.orderedlist'),
    icon: EditorIconListOrdered,
  },
]
const blockOptions = [
  {
    value: 'codeBlock',
    label: t('block.codeblock'),
    icon: EditorIconCodeBlock,
  },
  { value: 'blockquote', label: t('block.quote'), icon: EditorIconQuoteText },
]

type TextStyleOption = 'bold' | 'italic' | 'strike' | 'code'
type TextAlignOption = 'left' | 'center' | 'right'
type HeadingOption = 1 | 2 | 3
type ListOption = 'bulletList' | 'orderedList'
type BlockOption = 'codeBlock' | 'blockquote'

const selectedTextStyle = ref(textStyleOptions[0])
const selectedHeading = ref(headingOptions[0])
const selectedTextAlign = ref(textAlignOptions[0])
const selectedList = ref(listOptions[0])
const selectedBlock = ref(blockOptions[0])

const handleTextStyle = (option: Option<TextStyleOption>) => {
  const chain = props.editor!.chain().focus()

  switch (option.value) {
    case 'bold':
      chain.toggleBold().run()
      break
    case 'italic':
      chain.toggleItalic().run()
      break
    case 'strike':
      chain.toggleStrike().run()
      break
    case 'code':
      chain.toggleCode().run()
      break
  }

  selectedTextStyle.value = textStyleOptions.find(
    (o) => o.value === option.value
  )!
}

const handleTextAlign = (option: Option<TextAlignOption>) => {
  const chain = props.editor!.chain().focus()
  chain.setTextAlign(option.value).run()

  selectedTextAlign.value = textAlignOptions.find(
    (o) => o.value === option.value
  )!
}

const handleHeading = (option: Option<HeadingOption>) => {
  props.editor!.chain().focus().toggleHeading({ level: option.value }).run()
  selectedHeading.value = headingOptions.find((o) => o.value === option.value)!
}

const handleList = (option: Option<ListOption>) => {
  const chain = props.editor!.chain().focus()

  switch (option.value) {
    case 'bulletList':
      chain.toggleBulletList().run()
      break
    case 'orderedList':
      chain.toggleOrderedList().run()
      break
  }

  selectedList.value = listOptions.find((o) => o.value === option.value)!
}

const handleBlock = (option: Option<BlockOption>) => {
  const chain = props.editor!.chain().focus()

  switch (option.value) {
    case 'codeBlock':
      chain.toggleCodeBlock().run()
      break
    case 'blockquote':
      chain.toggleBlockquote().run()
      break
  }

  selectedBlock.value = blockOptions.find((o) => o.value === option.value)!
}
</script>

<template>
  <div class="control-group">
    <div v-if="editor" class="flex divide-x p-2 border-b border-slate-300">
      <Dropdown
        id="text-style-dropdown"
        class="toolbar-dropdown"
        :options="textStyleOptions"
      >
        <template #trigger>
          <component
            :is="selectedTextStyle.icon"
            :class="{
              'border-b-2 border-pink-500 -mb-px': editor.isActive(
                selectedTextStyle.value
              ),
            }"
          />
          <ChevronDownIcon />
        </template>
        <template #option="{ option }">
          <DropdownOption
            :active="editor.isActive(option.value)"
            :icon="option.icon"
            :label="option.label"
            :theme="option.theme"
            @click="handleTextStyle(option as Option<TextStyleOption>)"
          />
        </template>
      </Dropdown>

      <Dropdown
        id="text-align-dropdown"
        class="toolbar-dropdown"
        :options="textAlignOptions"
      >
        <template #trigger>
          <component
            :is="selectedTextAlign.icon"
            :class="{
              'border-b-2 border-pink-500 -mb-px': editor.isActive({
                textAlign: selectedTextAlign.value,
              }),
            }"
          />
          <ChevronDownIcon />
        </template>
        <template #option="{ option }">
          <DropdownOption
            :active="editor.isActive({ textAlign: option.value })"
            :icon="option.icon"
            :label="option.label"
            :theme="option.theme"
            @click="handleTextAlign(option as Option<TextAlignOption>)"
          />
        </template>
      </Dropdown>

      <Dropdown
        id="heading-dropdown"
        class="toolbar-dropdown"
        :options="headingOptions"
      >
        <template #trigger>
          <component
            :is="selectedHeading.icon"
            :class="{
              'border-b-2 border-pink-500 -mb-px': editor.isActive('heading', {
                level: selectedHeading.value,
              }),
            }"
          />
          <ChevronDownIcon />
        </template>
        <template #option="{ option }">
          <DropdownOption
            :active="editor.isActive('heading', { level: option.value })"
            :icon="option.icon"
            :label="option.label"
            :theme="option.theme"
            @click="handleHeading(option as Option<HeadingOption>)"
          />
        </template>
      </Dropdown>

      <Dropdown
        id="list-dropdown"
        class="toolbar-dropdown"
        :options="listOptions"
      >
        <template #trigger>
          <component
            :is="selectedList.icon"
            :class="{
              'border-b-2 border-pink-500 -mb-px': editor.isActive(
                selectedList.value
              ),
            }"
          />
          <ChevronDownIcon />
        </template>
        <template #option="{ option }">
          <DropdownOption
            :active="editor.isActive(option.value)"
            :icon="option.icon"
            :label="option.label"
            :theme="option.theme"
            @click="handleList(option as Option<ListOption>)"
          />
        </template>
      </Dropdown>

      <Dropdown
        id="block-dropdown"
        class="toolbar-dropdown"
        :options="blockOptions"
      >
        <template #trigger>
          <component
            :is="selectedBlock.icon"
            :class="{
              'border-b-2 border-pink-500 -mb-px': editor.isActive(
                selectedBlock.value
              ),
            }"
          />
          <ChevronDownIcon />
        </template>
        <template #option="{ option }">
          <DropdownOption
            :active="editor.isActive(option.value)"
            :icon="option.icon"
            :label="option.label"
            :theme="option.theme"
            @click="handleBlock(option as Option<BlockOption>)"
          />
        </template>
      </Dropdown>

      <div class="px-3 py-2 inline-flex">
        <LinkButton :editor="editor" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.toolbar-dropdown :deep(button) {
  @apply ring-0 bg-transparent border-none shadow-none;
}
</style>
