import { ref } from 'vue'
import type { Editor } from '@tiptap/vue-3'

export default function useLinkManager(editor?: Editor) {
  const isModalActive = ref(false)
  const linkInput = ref('')

  const toggleModal = (state: boolean) => {
    isModalActive.value = state
  }

  const showLinkModal = () => {
    if (!editor) return

    const { state } = editor
    const selectedText = state.doc.textBetween(
      state.selection.from,
      state.selection.to
    )

    if (selectedText) {
      linkInput.value = editor.getAttributes('link').href
      toggleModal(true)
    }
  }

  const submitLink = () => {
    if (!editor) return

    const url = linkInput.value.trim()

    if (!url) {
      unsetLink()
    } else {
      const sanitizedHref = /^https?:\/\//.exec(url) ? url : `https://${url}`

      editor
        .chain()
        .focus()
        .toggleLink({ href: sanitizedHref, target: '_blank' })
        .run()

      toggleModal(false)
      linkInput.value = ''
    }
  }

  const unsetLink = () => {
    if (!editor) return

    editor.chain().focus().unsetLink().run()
    toggleModal(false)
    linkInput.value = ''
  }

  return {
    isModalActive,
    linkInput,
    toggleModal,
    showLinkModal,
    submitLink,
    unsetLink,
  }
}
