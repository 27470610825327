<script setup lang="ts">
import noop from 'lodash/noop'

defineProps<{
  active?: boolean
}>()

// we need some dummy JS code to get this file in coverage report
noop()
</script>

<template>
  <button :class="{ 'border-b-2 border-pink-500 -mb-px': active }">
    <slot />
  </button>
</template>
